import '@fortawesome/fontawesome-pro/css/all.min.css'
import { AppContextProvider } from 'components/AppContext'
import { UserContextProvider } from 'components/useUser'
import moment from 'moment'
import 'moment/locale/id'
import { NextSeo } from 'next-seo'
import Head from 'next/head'
import { useRouter } from 'next/router'
import NextNprogress from 'nextjs-progressbar'
import { Fragment } from 'react'
import 'react-calendar/dist/Calendar.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'styles/app.scss'
import { SWRConfig } from 'swr'
import fetcher from 'util/fetcher'
moment.locale('id')

function MyApp({ Component, pageProps }) {
  const { pathname } = useRouter()
  return (
    <Fragment>
      <SWRConfig
        value={{
          fetcher,
        }}
      >
        <NextNprogress
          color="#0D47A1"
          startPosition={0.3}
          stopDelayMs={200}
          options={{ showSpinner: false }}
          height={4}
        />
        <AppContextProvider data={pageProps}>
          <UserContextProvider user={pageProps.user || {}}>
            <Head>
              <link
                href="https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;600;700&display=swap"
                rel="stylesheet"
              />
              <link
                rel="icon"
                type="image/svg+xml"
                href={require('images/logo-tutwuri.svg')}
              />
            </Head>
            <NextSeo titleTemplate="%s | Direktorat SMK" />
            <Component {...pageProps} />
          </UserContextProvider>
        </AppContextProvider>
      </SWRConfig>
      <style global>{`
      .svg-inline--fa {
        display: inline-block;
        font-size: inherit;
        height: 1em;
        overflow: visible;
        vertical-align: -0.125em;
      }
      `}</style>
      {!/^\/backoffice/i.test(pathname) && (
        <div
          dangerouslySetInnerHTML={{
            __html: `


      <script>
  (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
  })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

  ga('create', '${process.env.NEXT_PUBLIC_ANALYTICS_UA || `UA-42987507-4`
              }', 'auto');
  ga('send', 'pageview');

</script>

      `,
          }}
        />
      )}
    </Fragment>
  )
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext: AppContext) => {
//   const appProps = await App.getInitialProps(appContext)
//   if (appContext.ctx.req && appContext.ctx.res) {
//     const { req, res } = appContext.ctx
//     const nextMiddleware = require('util/nextMiddleware')
//     const _req = await nextMiddleware({ req, res })
//     appProps.pageProps.user = _req.user
//   }

//   return { ...appProps }
// }

export default MyApp
