import { User } from 'entity/User'
import react, { useContext, useEffect, useState } from 'react'
import useSWR from 'swr'
import fetcher, { Api } from 'util/fetcher'

const UserContext = react.createContext<ReturnType<typeof userContextValue>>(
  {} as any
)

const userContextValue = (user: User) => {
  const { data, error, mutate } = useSWR<User>('/auth/me', fetcher, {
    initialData: user,
    revalidateOnMount: true,
  })
  const [isLogin, setIsLogin] = useState(!!data.id)

  useEffect(() => {
    if (
      typeof error !== 'undefined' &&
      error.response &&
      error.response.status === 401
    ) {
      setIsLogin(false)
    } else {
      setIsLogin(true)
    }
  }, [error])

  const changeUserData = async (user) => {
    await Api().patch('/auth/me', user)
    mutate({ ...data, ...user }, true)
  }

  return {
    user: data,
    isLogin,
    changeUserData,
  }
}

export const UserContextProvider: React.FC<{ user: User }> = ({
  user,
  children,
}) => {
  const value = userContextValue(user)
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

const useUser = () => {
  return useContext(UserContext)
}

export default useUser
